import React, { useContext, useEffect, useRef, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Debug from 'debug'
import { useIntersectionObserver } from 'usehooks-ts'
import { Swiper as SwiperClass } from 'swiper/types'
import { animateScroll as scroll } from 'react-scroll'

import config from '../utils/siteConfig'
// import theme from '../styles/theme'

import { getProjectSections } from '../lib/getProjectSections'
// import { setOverflowOnBody } from '../lib/setOverflowOnBody'

import SEO from '../components/SEO'

import { ImageSection } from '../components/project-sections/ImageSection'
import ProjectHero from '../components/ProjectHero'
import SlideIndicator from '../components/SlideIndicator'
import { NavigationArrows } from '../components/NavigationArrows'

// import { SteppedImageSection } from '../components/SteppedImageSection'
import { ContentfulNode, Project, ProjectSection } from '../lib/types'
import { useAsyncEffect } from '../hooks/useAsyncEffect'
import { NavigationContext } from '../contexts/NavigationContext'
import { removePreloader } from '../lib/removePreloader'

import { ProjectsCarousel } from '../components/ProjectsCarousel'
import { RevealSection } from '../components/project-sections/RevealSection'
import { animationConfig } from '../utils/animationConfig'
import { InformationSection } from '../components/project-sections/InformationSection'
import { ScrollContext } from '../contexts/ScrollContext'
import { VersionNumber } from '../components/VersionNumber'

// https://github.com/webpack/react-starter/issues/37
const isBrowser = typeof window !== 'undefined'
const hasDocument = typeof document !== 'undefined'
const ScrollMagic = isBrowser ? require('ScrollMagic') : undefined

const debug = Debug('site:project-page')

interface Props {
  data: {
    contentfulProject: Project
    allContentfulProject: {
      edges: ContentfulNode[]
    }
  }
}

interface State {
  project: Project
  sections: ProjectSection[]
  isLoading: boolean
  // Carousel
  initialIndex: number | undefined
  activeIndex: number
  activeSlug: string
  downArrowVisible: boolean
  horizontalArrowsVisible: boolean
  projects: Project[]
  carouselProjects: Project[]
}

const ProjectPage: React.FC<Props> = ({ data }) => {
  const [state, setState] = useState<State>({
    project: data.contentfulProject,
    sections: [],
    isLoading: true,
    // Carousel
    initialIndex: undefined,
    activeIndex: 0,
    activeSlug: undefined,
    downArrowVisible: true,
    horizontalArrowsVisible: true,
    projects: data.allContentfulProject.edges.map((edge) => edge.node),
    carouselProjects: []
  })

  const [carouselSwiper, setCarouselSwiper] = useState<SwiperClass>(null)
  const [controller, setController] = useState(
    isBrowser ? new ScrollMagic.Controller() : null
  )

  const { shouldScroll, setShouldScroll } = useContext(ScrollContext)

  /**
   * Navigation colours
   */
  const { setNavigationColour } = useContext(NavigationContext)

  const carouselRef = useRef()
  const carouselObserver = useIntersectionObserver(carouselRef, {
    threshold: 0.9
  })
  const carouselVisible = !!carouselObserver?.isIntersecting

  const heroRef = useRef()
  const heroObserver = useIntersectionObserver(heroRef, {
    threshold: 0.1
  })
  const heroVisible = !!heroObserver?.isIntersecting

  useEffect(() => {
    if (heroVisible && !carouselVisible) {
      setNavigationColour('#FFF')
    }
    if (!heroVisible && !carouselVisible) {
      setNavigationColour('#000')
    }

    if (carouselVisible && !heroVisible) {
      setNavigationColour('#FFF')
    }
    if (!carouselVisible && !heroVisible) {
      setNavigationColour('#000')
    }
  }, [carouselVisible, heroVisible])

  // Initial load
  useAsyncEffect(async () => {
    debug('useEffect')
    removePreloader()

    // Page sections
    const sections = await getProjectSections(state.project)

    const carouselProjects = state.projects.filter(
      (project) => project.slug !== state.project.slug
    )

    // Find active index
    let activeIndex = 0
    let initialIndex
    let activeSlug

    state.projects.map((project, index) => {
      // current project
      if (project.slug === state.project.slug) {
        // - 1 as the slides shown filter out current project
        activeIndex = index
        initialIndex = index

        // Loop back on last project
        if (index === state.projects.length - 1) {
          activeSlug = carouselProjects[0].slug
        } else {
          activeSlug = carouselProjects[index].slug
        }
      }
    })

    setState({
      ...state,
      isLoading: false,
      sections,
      initialIndex,
      activeIndex,
      activeSlug,
      carouselProjects
    })

    return () => {
      controller.destroy(true)
      setController(null)
    }
  }, [])

  /**
   * Scroll to information section if we have navigated to this page
   */
  useEffect(() => {
    if (shouldScroll) {
      scroll.scrollMore(window.innerHeight, {
        duration: animationConfig.slideDuration,
        delay: animationConfig.slideDelay,
        smooth: animationConfig.slideEasing
      })
      setShouldScroll(false)
    }
  }, [])

  /**
   * Carousel swiper
   */
  const handleCarouselChange = (swiper: SwiperClass) => {
    const destinationIndex = swiper.realIndex
    const activeSlug = state.carouselProjects[destinationIndex].slug
    debug('go to slide', destinationIndex, activeSlug)

    setState({
      ...state,
      activeSlug,
      activeIndex: destinationIndex,
      horizontalArrowsVisible: false
    })
  }

  const handleCarouselChangeStart = (swiper: SwiperClass) => {}

  const handleCarouselChangeEnd = () => {
    // show horizontal arrows after slide has transitioned
    setState({
      ...state,
      horizontalArrowsVisible: true
    })
  }

  const handleNavigateAndScrollProject = (slug: string) => {
    setShouldScroll(true)
    navigate(`/${slug}`)
  }

  const { isLoading, sections, project } = state

  // Prevent scrolling while loading
  // isLoading ? setOverflowOnBody('hidden') : setOverflowOnBody('auto')
  document.documentElement.style.overflow = 'visible'

  // debug('render++', state)
  return (
    <>
      <SEO />
      <Helmet>
        <title>{`${project.name} | ${config.siteTitle}`}</title>
      </Helmet>

      <div ref={heroRef}>
        <ProjectHero project={state.project} isLoading={isLoading} />
      </div>

      <div style={{ position: 'relative', height: '100%' }}>
        {sections.map((section, index) => {
          switch (section.sectionType) {
            case 'information':
              return (
                <InformationSection data={section.information} key={index} />
              )

            case 'swipe':
              return (
                <RevealSection
                  slides={section.slides}
                  controller={controller}
                  key={index}
                  sectionIndex={index}
                />
              )
            // case 'swipe':
            //   return (
            //     <SteppedImageSection
            //       images={section.slides}
            //       key={index}
            //       sectionIndex={index}
            //     />
            //   )
            case 'image':
              return <ImageSection data={section} key={index} />
            default:
              return null
          }
        })}
      </div>

      <section style={{ position: 'relative' }} ref={carouselRef}>
        {isLoading ? null : (
          <>
            <ProjectsCarousel
              projects={state.carouselProjects}
              initialIndex={state.initialIndex}
              onSwiper={setCarouselSwiper}
              onSlideChange={handleCarouselChange}
              onSlideChangeTransitionStart={handleCarouselChangeStart}
              onSlideChangeTransitionEnd={handleCarouselChangeEnd}
              handleNavigateProject={handleNavigateAndScrollProject}
            />

            <SlideIndicator
              index={state.activeIndex}
              numberOfProjects={state.carouselProjects.length}
            />

            <NavigationArrows
              horizontalArrowsVisible={state.horizontalArrowsVisible}
              downArrowVisible={state.downArrowVisible}
              handleNavigateProject={() =>
                handleNavigateAndScrollProject(state.activeSlug)
              }
              handlePrevious={() => carouselSwiper.slidePrev()}
              handleNext={() => carouselSwiper.slideNext()}
            />
          </>
        )}
      </section>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      name
      slug
      tagline
      textColor
      position
      heroBackground {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      heroBackgroundMobile {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      information {
        keyQuote {
          internal {
            content
          }
        }
        description {
          internal {
            content
          }
        }
        services
        client
        location
        expertise
        scale {
          internal {
            content
          }
        }
        budget
        year
        team
        collaborators {
          internal {
            content
          }
        }
        images {
          internal {
            content
          }
        }
      }
      swipeSections {
        name
        sectionPosition
        slides {
          caption {
            internal {
              content
            }
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      imageSections {
        sectionPosition
        imagePosition
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        imageMobile {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        caption {
          internal {
            content
          }
        }
      }
    }
    allContentfulProject(
      sort: { fields: position, order: ASC }
      filter: { active: { eq: true } }
    ) {
      edges {
        node {
          name
          slug
          active
          tagline
          textColor
          position
          heroBackground {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          heroBackgroundMobile {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default ProjectPage
