import React from 'react'
import styled from 'styled-components'
import { Transition } from 'react-spring/renderprops'
import Debug from 'debug'

import { media } from '../styles/utils'
import { defaultSpring } from '../utils/Springs'

import ProjectLoader from './loaders/ProjectLoader'
import ArrowVertical from './svg/ArrowVertical'
import { BackgroundImage } from './project-sections/ImageSection/BackgroundImage'
import { MasterGrid } from './layout/MasterGrid'
import { HeroTextContainer } from './layout/HeroTextContainer'
import { Project } from '../lib/types'

const debug = Debug('site:project-hero')

const Screen = styled(MasterGrid)`
  position: ${(props) => (props.isLoading ? `fixed` : `relative`)};
  top: 0;
  left: 0;
  z-index: ${(props) =>
    props.isLoading ? props.theme.layers.loaders : `inherit`};
  width: 100%;
  height: 100vh;
`

const ArrowContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.sizes.slidePadding}px;
  z-index: 4;
  padding: 1em;
  cursor: pointer;

  left: 50%;
  transform: translateX(-50%);

  ${media.tablet`
    bottom: ${(props) => props.theme.sizes.slidePaddingMobile}px;
  `}
`

const BounceAnimation = styled.div`
  animation: bounce 2s infinite;

  @keyframes bounce {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, 10px);
    }
    40% {
      transform: translate(0, 0);
    }
  }
`

interface Props {
  project: Project
  isLoading: boolean
}

export const ProjectHero: React.FC<Props> = ({ project, isLoading }) => {
  // debug('render', project)
  return (
    <div className="section">
      <Screen isLoading={isLoading}>
        <BackgroundImage
          image={project.heroBackground.gatsbyImageData}
          mobileImage={project.heroBackgroundMobile?.gatsbyImageData}
        />

        <HeroTextContainer>
          <span>
            <h1 className="font-align" style={{ color: project.textColor }}>
              {project.name}
            </h1>
          </span>
          <span>
            <p
              className="subtitle font-align mb-0"
              style={{ color: project.textColor }}
            >
              {project.tagline}
            </p>
          </span>
        </HeroTextContainer>

        <Transition
          config={defaultSpring}
          items={isLoading}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(show) =>
            show && ((style) => <ProjectLoader style={style} color={'#FFF'} />)
          }
        </Transition>

        <Transition
          config={defaultSpring}
          items={!isLoading}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(show) =>
            show &&
            ((style) => (
              <ArrowContainer style={style}>
                <BounceAnimation>
                  <ArrowVertical color={project.textColor} scale={0.8} />
                </BounceAnimation>
              </ArrowContainer>
            ))
          }
        </Transition>
      </Screen>
    </div>
  )
}

export default ProjectHero
