import React, { useEffect } from 'react'
import styled from 'styled-components'

import { media } from '../../styles/utils'

import { MasterGrid } from '../layout/MasterGrid'
import { Caption, TextElement } from '../Caption'
// https://github.com/webpack/react-starter/issues/37
const isBrowser = typeof window !== 'undefined'
const ScrollMagic = isBrowser ? require('ScrollMagic') : undefined

// if (isBrowser) {
//   require('debug.addIndicators')
// }

const SectionContainer = styled.section``

const Slide = styled.div`
  height: 100vh;
  width: 100%;
  left: 0;
  position: relative;
  background: ${(props) => props.background};
`

const ContentContainer = styled(MasterGrid)`
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 50%;
  // transform: translateX(-50%);
  transform: translate3d(-50%, 0px, 0px); // safari
  margin: ${(props) => props.margin};
`

const ImageInner = styled.div`
  grid-area: 4 / 4 / 5 / 8;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.large`
    grid-column: 3 / 9;
  `}

  ${media.tablet`
    grid-row: 3 / 6;
  `}

  ${media.phone`
    grid-row: 4 / 5;
    grid-column: 2 / 10;
  `}
`

const Image = styled.img`
  height: 100%;
  width: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  controller: any
  slides: any[]
  sectionIndex: any
}

export const RevealSection: React.FC<Props> = ({
  controller,
  slides,
  sectionIndex
}) => {
  const numSlides = slides.length

  useEffect(() => {
    // Diagrams
    slides.map((_, index) => {
      // first and last slide must have a scene duration of 100%
      if (index === 0 || index === numSlides - 1) {
        let scene = new ScrollMagic.Scene({
          triggerElement: `#pin-${sectionIndex}-${index}`,
          triggerHook: index === 0 ? 0 : 1, // first slide must trigger effect once it reaches top of screen
          duration: '100%'
        })
          .setPin(`#pin-${sectionIndex}-${index}`, { pushFollowers: false })
          .addTo(controller)

        // if (process.env.NODE_ENV === 'development') {
        //   scene.addIndicators({ name: index })
        // }
        return scene
      } else {
        let scene = new ScrollMagic.Scene({
          triggerElement: `#pin-${sectionIndex}-${index}`,
          triggerHook: index === 0 ? 0 : 1
        })
          .setPin(`#pin-${sectionIndex}-${index}`, { pushFollowers: false })
          .addTo(controller)

        // if (process.env.NODE_ENV === 'development') {
        //   scene.addIndicators({ name: index })
        // }
        return scene
      }
    })

    // Captions
    slides.map((_, index) => {
      return (
        new ScrollMagic.Scene({
          triggerElement: `#pin-${sectionIndex}-${index}`,
          triggerHook: 0.25,
          duration: `75%`
        })
          .setClassToggle(`#caption-${sectionIndex}-${index}`, 'fade')
          // .addIndicators({ name: '2 (fade)' })
          .addTo(controller)
      )
    })
  }, [])

  return (
    <div className="section">
      <SectionContainer>
        {slides.map((slide, index) => {
          return (
            <Slide className="reveal" background={`#FFF`} key={index}>
              <div id={`pin-${sectionIndex}-${index}`}>
                <ContentContainer margin={index === 0 ? `0` : `-100vh 0`}>
                  <ImageInner>
                    <Image src={slide.gatsbyImageData.images.fallback.src} />
                  </ImageInner>

                  <Caption
                    id={`caption-${sectionIndex}-${index}`}
                    className="subtitle"
                    element={TextElement.Reveal}
                  >
                    {slide.caption}
                  </Caption>
                </ContentContainer>
              </div>
            </Slide>
          )
        })}
      </SectionContainer>
    </div>
  )
}
