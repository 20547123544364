import { IGatsbyImageData } from 'gatsby-plugin-image'

export enum ImagePosition {
  Fullscreen = 'Fullscreen',
  SetIn = 'Set In',
  InnerFrame = 'Inner Frame'
}

export interface BaseSection {
  sectionType: 'information' | 'swipe' | 'image'
  sectionPosition: number
}

export interface InformationSection extends BaseSection {
  information: {
    keyQuote: {
      internal: {
        content: string
      }
    }
    description: {
      internal: {
        content: string
      }
    }
    services: string
    client: string
    location: string
    expertise: string
    scale: {
      internal: {
        content: string
      }
    }
    budget: string
    year: string
    team: string
    collaborators: {
      internal: {
        content: string
      }
    }
    images: {
      internal: {
        content: string
      }
    }
  }
}

export interface ProjectSlide {
  gatsbyImageData: IGatsbyImageData
  caption: string
}
export interface SwipeSection extends BaseSection {
  slides: ProjectSlide[]
}

export interface IImageSection extends BaseSection {
  imagePosition: ImagePosition
  image: IGatsbyImageData
  mobileImage: IGatsbyImageData | null
  caption: string
}

export type ProjectSection = InformationSection | SwipeSection | IImageSection
