import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { media } from '../../styles/utils'

import { MasterGrid } from '../layout/MasterGrid'

const Container = styled(MasterGrid)`
  grid-template-rows: 36px 50px 80px auto 80px 50px 36px;
  width: 100%;
  min-height: 100vh;
  overflow: visible;
  ${media.tablet`
    grid-template-rows: 20px 50px 80px 1fr 80px 50px 20px;
  `}
`

const Content = styled.div`
  grid-row: 4 / 5;
  grid-column: 4 / 8;

  display: grid;
  grid-template-columns: 680px 1fr;

  ${media.xlarge`
    grid-template-columns: auto 428px;
  `}

  ${media.large`
    display: block;
    grid-column: 3 / 9;
  `}

  ${media.phone`
    grid-column: 2 / 10;
  `}
`

const MetaContainer = styled.div`
  // min-width: 428px;
  max-width: 520px;

  // margin-top: 69.528px;
  padding-left: 7.45em;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4em;
  align-content: start;

  ${media.large`
    margin-top: 8em;
    grid-template-columns: repeat(2, 1fr);

    padding-left: 0;
    width: auto;
  `}
`

interface MetaCardProps {
  wide?: boolean
}

const MetaCard = styled.div<MetaCardProps>`
  max-width: 200px;
  grid-column: ${(p) => (p.wide ? `span 2` : ``)};
`

const MetaTitle = styled.p`
  font-family: 'HelveticaLtStd-Bold';
  margin: 0;
  margin-bottom: 0.2em;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`
const MetaText = styled.p`
  white-space: pre-line;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
`

interface InternalContent {
  internal: {
    content: string
  }
}

interface ProjectInformation {
  description?: InternalContent
  scale?: InternalContent
  collaborators?: InternalContent
  images?: InternalContent
  client: string
  location: string
  year: string
  budget: string
  expertise: string
  team: string
}

interface Props {
  data: ProjectInformation
}

export const InformationSection: React.FC<Props> = ({ data }) => {
  let description = null
  if (data.description) {
    description = data.description.internal.content
  }

  let scale = null
  if (data.scale) {
    scale = data.scale.internal.content
  }

  let collaborators = null
  if (data.collaborators) {
    collaborators = data.collaborators.internal.content
  }

  let images = null
  if (data.images) {
    images = data.images.internal.content
  }

  return (
    <div className="section">
      <Container>
        <Content>
          <ReactMarkdown source={description} className="markdown-content" />

          <MetaContainer>
            {data.client && (
              <MetaCard>
                <MetaTitle>Client</MetaTitle>
                <MetaText>{data.client}</MetaText>
              </MetaCard>
            )}

            {data.location && (
              <MetaCard>
                <MetaTitle>Location</MetaTitle>
                <MetaText>{data.location}</MetaText>
              </MetaCard>
            )}

            {data.year && (
              <MetaCard>
                <MetaTitle>Year</MetaTitle>
                <MetaText>{data.year}</MetaText>
              </MetaCard>
            )}

            {scale && (
              <MetaCard>
                <MetaTitle>Scale</MetaTitle>
                <MetaText>{scale}</MetaText>
              </MetaCard>
            )}

            {data.budget && (
              <MetaCard>
                <MetaTitle>Budget</MetaTitle>
                <MetaText>{data.budget}</MetaText>
              </MetaCard>
            )}

            {data.expertise && (
              <MetaCard>
                <MetaTitle>Expertise</MetaTitle>
                <MetaText>{data.expertise}</MetaText>
              </MetaCard>
            )}

            {images && (
              <MetaCard>
                <MetaTitle>Images</MetaTitle>
                <MetaText>{images}</MetaText>
              </MetaCard>
            )}

            {data.team && (
              <MetaCard wide>
                <MetaTitle>Project Team</MetaTitle>
                <MetaText>{data.team}</MetaText>
              </MetaCard>
            )}

            {collaborators && (
              <MetaCard wide>
                <MetaTitle>Collaborators</MetaTitle>
                <MetaText>{collaborators}</MetaText>
              </MetaCard>
            )}
          </MetaContainer>
        </Content>
      </Container>
    </div>
  )
}
