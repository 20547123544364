import {
  InformationSection,
  Project,
  ProjectSection,
  ProjectSlide
} from './types'
import Debug from 'debug'

const debug = Debug('site:get-project-sections')

export const getProjectSections = (
  project: Project
): Promise<ProjectSection[]> => {
  return new Promise((resolve, reject) => {
    debug('getting project sections...')

    const { information, swipeSections, imageSections } = project

    const projectSections: ProjectSection[] = []

    // Information Section
    if (information) {
      const section: InformationSection = {
        sectionType: 'information',
        sectionPosition: 0,
        information
      }
      projectSections.push(section)
    }

    // Swipe Sections
    if (swipeSections) {
      swipeSections.map((section) => {
        debug('swipeSections', section)
        let flattenedSlides: ProjectSlide[] = []
        section.slides.map((slide) => {
          if (slide.image !== null) {
            flattenedSlides.push({
              gatsbyImageData: slide.image.gatsbyImageData,
              caption: slide.caption ? slide.caption.internal.content : ''
            })
          }
        })

        projectSections.push({
          sectionType: 'swipe',
          sectionPosition: section.sectionPosition,
          slides: flattenedSlides
        })
      })
    }

    // Image Sections
    if (imageSections) {
      imageSections.map((section) => {
        // check if image section has a valid image
        if (section.image && section.image.fluid !== null) {
          projectSections.push({
            sectionType: 'image',
            sectionPosition: section.sectionPosition,
            imagePosition: section.imagePosition,
            image: section.image.gatsbyImageData,
            mobileImage: section.imageMobile
              ? section.imageMobile.gatsbyImageData
              : null,
            caption: section.caption ? section.caption.internal.content : ''
          })
        }
      })
    }

    // Sort project sections
    projectSections.sort((a, b) => {
      return a.sectionPosition - b.sectionPosition
    })

    resolve(projectSections)
  })
}
