import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { media, screenSizes } from '../../../styles/utils'
import { ImageComponentProps } from './types'

const Container = styled.div`
  grid-row: 3 / 6;
  grid-column: 5 / 7;
  margin: 0 62px;
  overflow: hidden;
  position: relative;
  width: 100%;
  justify-self: center;

  ${media.tablet`
    grid-column: 3 / 9;
    width: 100%;
  `}

  ${media.phone`
    grid-column: 2 / 10;
    width: 100%;
  `}
`

const Image = styled(GatsbyImage)`
  width: auto;
  height: 100%;
  display: block;
  pointer-events: none;
`

export const SetImage: React.FC<ImageComponentProps> = ({
  image,
  mobileImage
}) => {
  let display = image
  if (mobileImage && window.innerWidth < screenSizes.tablet) {
    display = mobileImage
  }

  return (
    <Container>
      <Image
        image={display}
        alt="set-image"
        imgStyle={{
          objectFit: 'contain'
        }}
      />
    </Container>
  )
}
