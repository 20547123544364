import React from 'react'
import styled from 'styled-components'
import { media } from '../styles/utils'

interface TextProps {
  light: boolean
}

const Text = styled.p<TextProps>`
  font-family: ${(p) => p.theme.fonts.medium};
  grid-row: 6;
  grid-column: 3 / 9;
  justify-self: center;
  align-self: end;
  margin: 0;
  z-index: 1;

  ${media.tablet`
    grid-row: 5;
    grid-column: 2 / 10;
    justify-self: center;
    text-align: center;
  `}
`

const RevealText = styled.p<TextProps>`
  font-family: ${(p) => p.theme.fonts.medium};
  grid-row: 6;
  grid-column: 3 / 9;
  justify-self: center;
  align-self: end;
  margin: 0;
  z-index: 1;

  ${media.large`
    grid-row: 5;
    grid-column: 2 / 10;
    justify-self: center;
    text-align: center;
  `}

  ${media.tablet`
    grid-row: 6;
  `}

  ${media.phone`
    grid-row: 5;
  `}
`

export enum TextElement {
  Default = 'Default',
  Reveal = 'Reveal'
}

const TextComponents = {
  [TextElement.Default]: Text,
  [TextElement.Reveal]: RevealText
}

interface Props {
  light?: boolean
  children: React.ReactNode
  element?: TextElement
}

export const Caption: React.FC<Props> = ({
  light,
  children,
  element = TextElement.Default,
  ...props
}) => {
  const Element = TextComponents[element]
  return (
    <Element light={light} className="subtitle" {...props}>
      {children}
    </Element>
  )
}
