import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { media, screenSizes } from '../../../styles/utils'
import { ImageComponentProps } from './types'

const Container = styled.div`
  grid-area: 4 / 4 / 5 / 8;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.large`
    grid-column: 3 / 9;
  `}

  ${media.tablet`
    grid-column: 2 / 10;
  `}
`

const Image = styled(GatsbyImage)`
  height: 100%;
  display: block;
  pointer-events: none;
`

export const InnerImage: React.FC<ImageComponentProps> = ({
  image,
  mobileImage
}) => {
  let display = image
  if (mobileImage && window.innerWidth < screenSizes.tablet) {
    display = mobileImage
  }

  return (
    <Container>
      <Image
        image={display}
        alt="inner-image"
        imgStyle={{
          objectFit: 'contain'
        }}
      />
    </Container>
  )
}
