import React from 'react'
import styled from 'styled-components'

import { IImageSection, ImagePosition } from '../../../lib/types'

import { BackgroundImage } from './BackgroundImage'
import { SetImage } from './SetImage'
import { InnerImage } from './InnerImage'
import { Caption } from '../../Caption'

import { media } from '../../../styles/utils'

const ImageComponents = {
  [ImagePosition.Fullscreen]: BackgroundImage,
  [ImagePosition.SetIn]: SetImage,
  [ImagePosition.InnerFrame]: InnerImage
}

interface ScreenProps {
  position: ImagePosition
}

const Screen = styled.div<ScreenProps>`
  display: grid;
  grid-template-rows: 36px 50px 80px 1fr 80px 50px 36px;
  grid-template-columns: 36px 50px 80px 0.248fr 0.252fr 0.252fr 0.248fr 80px 50px 36px;
  overflow: hidden;

  width: 100%;
  height: 100vh;
  position: relative;

  ${media.large`
    max-height: none;
  `}

  ${media.desktop`
    grid-template-rows: 1fr;
    grid-template-columns: 20px 50px 80px 0.248fr 0.252fr 0.252fr 0.248fr 80px 50px 20px;
    margin: 70px 0;
    height: auto;
    max-height: 80vw;
    ${(props) =>
      props.position === ImagePosition.Fullscreen
        ? `
      height: 80vw;
      max-height: 100vh;
    `
        : ``}
  `}

  ${media.tablet`
    margin: 20px 0;
  `}

  ${media.phone`
    max-height: none;
  `}
`

// If backound image
// height: 80vw;
// max-height: 100vh;

interface Props {
  data: IImageSection
}

export const ImageSection: React.FC<Props> = ({ data }) => {
  const renderImage = () => {
    let ImageComponent = ImageComponents[data.imagePosition]
    if (!ImageComponent) {
      return console.error('No image component found')
    }
    return <ImageComponent image={data.image} mobileImage={data.mobileImage} />
  }

  return (
    <Screen position={data.imagePosition}>
      {renderImage()}
      <Caption
        className="subtitle"
        light={data.imagePosition === ImagePosition.Fullscreen}
        style={{
          color:
            data.imagePosition === ImagePosition.Fullscreen ? '#FFF' : '#000'
        }}
      >
        {data.caption}
      </Caption>
    </Screen>
  )
}
